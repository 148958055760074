<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>Jugend Endtabelle</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ selectedLeague.name }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <section>
          <VueScrollingTable class='responstable' :style="selectedLeague.name.includes('Bezirksliga') ? 'max-width: 404px;' : 'max-width: 564px;'">
          <template #thead>
            <tr>
              <th style="min-width: 84px; width: 84px;">Platz</th>
              <th style="min-width: 160px; width: 160px;">Mannschaft</th>
              <th style="min-width: 160px; width: 160px;" v-if="!selectedLeague.name.includes('Bezirksliga')">Gesamt über CR-Wert</th>
              <th style="min-width: 160px; width: 160px;" v-if="!selectedLeague.name.includes('Bezirksliga')">Ø über CR-Wert</th>
              <th style="min-width: 160px; width: 160px;" v-if="selectedLeague.name.includes('Bezirksliga')">Ø Anzahl Bruttopunkte</th>
            </tr>
          </template>
          <template #tbody>
            <tr v-for="(result, i) in resultFound" :key="i" :value="result">
              <td style="min-width: 84px; width: 84px;">
                <ion-icon v-if="result.up" style="zoom: 1.2;" color="success" :md="arrowUpSharp" :ios="arrowUpSharp"></ion-icon>
                <ion-icon v-if="result.down" style="zoom: 1.2;" color="danger" :md="arrowDownSharp" :ios="arrowDownSharp"></ion-icon>
                <span>{{ result.rang }}</span>
              </td>
              <td style="min-width: 160px; width: 160px;">
                <span>{{ '&nbsp;&nbsp;' + result.name }}</span>
              </td>
              <td style="min-width: 160px; width: 160px;" v-if="!selectedLeague.name.includes('Bezirksliga')">
                <span>{{ result.score }}</span>
              </td>
              <td style="min-width: 160px; width: 160px;">
                <span>{{ result.averageScore }}</span>
              </td>
            </tr>
          </template>
          </VueScrollingTable>
        </section>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonListHeader,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, arrowUpSharp, arrowDownSharp } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import * as dayjs from 'dayjs'; 
import findJugendEndtabelleQueryFile from '../graphql/findJugendEndtabelle.query.gql'
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

export default defineComponent({
  name: 'ShowResultListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonListHeader, IonAvatar, VueScrollingTable
              },
  props: ['propsData'],
  setup(props) {
    let selectedLeague = props.propsData.selectedLeague

    const {result: findJugendEndtabelleResult } = useQuery(findJugendEndtabelleQueryFile, () => ({ leagueId: selectedLeague.leagueId }), { fetchPolicy: 'no-cache' } )
    const resultFound = useResult(findJugendEndtabelleResult, null, data => data.findJugendEndtabelle.result)

    return { 
      closeOutline, selectedLeague, resultFound, arrowUpSharp, arrowDownSharp
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>
/* -------------------- Page Styles (not required) */
div { margin: 0px; }

/* -------------------- Select Box Styles: bavotasan.com Method (with special adaptations by ericrasch.com) */
/* -------------------- Source: http://bavotasan.com/2011/style-select-box-using-only-css/ */

P   { 
	text-align: center; 
	margin:0px;
}

.styled-select {
   height: 29px;
   overflow: hidden;
   width: 180px;
  -webkit-print-color-adjust: exact;
}

.styled-select select {
   background: transparent;
   border: none;
   font-size: 14px;
   height: 29px;
   padding: 5px; /* If you add too much padding here, the options won't show in IE */
   width: 368px;
  -webkit-print-color-adjust: exact;
}

.styled-select.slate {
   height: 34px;
   width: 340px;
  -webkit-print-color-adjust: exact;
}

.styled-select.slate select {
   border: 1px solid #ccc;
   font-size: 16px;
   height: 34px;
   width: 368px;
  -webkit-print-color-adjust: exact;
}

/* -------------------- Rounded Corners */
.rounded {
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
  -webkit-print-color-adjust: exact;
}

.semi-square {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
  -webkit-print-color-adjust: exact;
}

/* -------------------- Colors: Background */
.slate   { background-color: #ddd; }
.green   { background-color: #779126; }
.blue    { background-color: #3b8ec2; }
.yellow  { background-color: #eec111; }
.black   { background-color: #000; }

/* -------------------- Colors: Text */
.slate select   { color: #000; }
.green select   { color: #fff; }
.blue select    { color: #fff; }
.yellow select  { color: #000; }
.black select   { color: #fff; }


/* -------------------- Select Box Styles: danielneumann.com Method */
/* -------------------- Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ */
#mainselection select {
   border: 0;
   color: #EEE;
   background: transparent;
   font-size: 20px;
   font-weight: bold;
   padding: 2px 10px;
   width: 478px;
   *width: 350px;
   *background: #58B14C;
   -webkit-appearance: none;
  -webkit-print-color-adjust: exact;
}

#mainselection {
   overflow:hidden;
   width:450px;
   -moz-border-radius: 9px 9px 9px 9px;
   -webkit-border-radius: 9px 9px 9px 9px;
   border-radius: 9px 9px 9px 9px;
   box-shadow: 1px 1px 11px #330033;
   background: #58B14C no-repeat scroll 319px center;
  -webkit-print-color-adjust: exact;
}


/* -------------------- Select Box Styles: stackoverflow.com Method */
/* -------------------- Source: http://stackoverflow.com/a/5809186 */
select#soflow, select#soflow-color {
   -webkit-appearance: button;
   -webkit-border-radius: 2px;
   -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
   -webkit-padding-end: 20px;
   -webkit-padding-start: 2px;
   -webkit-user-select: none;
  -webkit-print-color-adjust: exact;
   background-position: 97% center;
   background-repeat: no-repeat;
   border: 1px solid #AAA;
   color: #555;
   font-size: inherit;
   margin: 0px;
   overflow: hidden;
   padding: 5px 10px;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 700px;
}

select#soflow-color {
   color: #fff;
   background-color: #00a684;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
   padding-left: 15px;
  -webkit-print-color-adjust: exact;
}

.responstable {
  margin: 1em 0;
  width: 100%;
  overflow: hidden;
  background: #FFF;
  color: #024457;
  border-radius: 10px;
  border: 1px solid #ff5560;
  -webkit-print-color-adjust: exact;
}
.responstable tr {
  border: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
}
.responstable tr:nth-child(odd) {
  background-color: #EAF3F3;
  -webkit-print-color-adjust: exact;
}
.responstable th {
  display: none;
  border: 1px solid #FFF;
  background-color: #ff5560;
  color: #FFF;
  padding: 1em;
  -webkit-print-color-adjust: exact;
}
.responstable th:first-child {
  display: table-cell;
  text-align: center;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2) {
  display: table-cell;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2) span {
  display: none;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2):after {
  content: attr(data-th);
  -webkit-print-color-adjust: exact;
}
@media (min-width: 180px) {
  .responstable th:nth-child(2) span {
    display: block;
  -webkit-print-color-adjust: exact;
  }
  .responstable th:nth-child(2):after {
    display: none;
  -webkit-print-color-adjust: exact;
  }
}
.responstable td {
  display: block;
  word-wrap: break-word;
  max-width: 7em;
  -webkit-print-color-adjust: exact;
}
.responstable td:first-child {
  display: table-cell;
  text-align: center;
  border-right: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
}
@media (min-width: 180px) {
  .responstable td {
    border: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
  }
}
.responstable th, .responstable td {
  text-align: left;
  margin: .5em 1em;
  -webkit-print-color-adjust: exact;
}
@media (min-width: 180px) {
  .responstable th, .responstable td {
    display: table-cell;
    padding: 1em;
  -webkit-print-color-adjust: exact;
  }
}

body {
  padding: 0 2em;
  font-family: Arial, sans-serif;
  color: #00a684;
  background: #f2f2f2;
  -webkit-print-color-adjust: exact;
}

h1 {
  font-family: Verdana;
  font-weight: normal;
  color: #024457;
  -webkit-print-color-adjust: exact;
}
h1 span {
  color: #ff5560;
  -webkit-print-color-adjust: exact;
}


/* ion-col[class*="result"]:first-of-type {border-left: 1px solid grey; }

.responstable ion-row:nth-child(odd) {
  background-color: #EAF3F3;
}*/

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  padding: 0 2em;
  font-family: Arial, sans-serif;
  color: #00a684;
  background: #f2f2f2;
  -webkit-print-color-adjust: exact;
}

section:not(.full-width),
.full-width > header {
  padding: 10px 10px;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-list {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-list:first-of-type {
  border-top: 0.55px solid #c8c7cc;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>