<template>
  <ion-page>
  
    <ion-content :fullscreen="true">
      
        <ion-header>
          <table>
            <tr><td> <ion-img style="width: 69px; height:69px;" :src="logo" /></td>
              <td>
                <ion-select interface="action-sheet" v-model="selectedLeague" @ionChange="changeLeague($event.target.value)">
                  <ion-select-option v-for="(item, i) in leaguesFound" :key="i" :value="item">{{ item.name }}</ion-select-option>
                </ion-select>
                <p v-if="selectedLeague && selectedLeague.subLeagues">
                <ion-select interface="action-sheet" v-model="selectedSubLeague" @ionChange="changeSubLeague($event.target.value)">
                  <ion-select-option v-for="(item, i) in selectedLeague.subLeagues" :key="i" :value="item">{{ item.name }}</ion-select-option>
                </ion-select>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <ion-checkbox class="ion-margin" v-model="showAll"></ion-checkbox>
              </td>
              <td>
                <ion-label>Gesamtansicht</ion-label>
              </td>
            </tr>
            <tr v-if="year < 2023 || (selectedLeague && selectedLeague.name.startsWith('Jugendliga Bezirksliga'))">
              <td colspan="2">
                <ion-label class="clickable ion-margin" @click="showJugendEndTabelle()" v-if="selectedLeague && selectedLeague.name.startsWith('Jugend')" style="color: black;">Jugend Endtabelle</ion-label>
              </td>
            </tr>
          </table>
          <ion-item-divider></ion-item-divider>    
      </ion-header>
      <ion-grid style="padding: 0px;">
        <ion-row class="ion-rowheader">
          <ion-button fill="clear" @click="toggleAll()">
            <ion-icon style="zoom: 1.2; padding-top: 8px;" color="primary" :md="useVisibilty == true ? contractOutline : expandOutline" :ios="useVisibilty == true ? contractOutline : expandOutline"></ion-icon>
          </ion-button>
          <h1><span v-if="selectedLeague">{{ selectedLeague.name }}</span></h1>
        </ion-row>
      </ion-grid>
      <section v-for="(result, i) in resultFound" :key="i" :value="result" >
          <ion-grid fixed style="padding: 0px;">
            <ion-row class="ion-rowheader">
              <ion-col size="auto">
                <ion-button v-if="!showAll && selectedSubLeague && selectedSubLeague != selectedLeague.subLeagues[0]" fill="clear" @click="goBack()">
                  <ion-icon style="zoom: 1.4;" color="primary" :md="chevronBackOutline" :ios="chevronBackOutline"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col v-if="selectedSubLeague" size="auto" style="text-align: center;" @click="toggleAll(result)">
                <ion-label style="color: #00a684; font-size: 20px;">{{ result.name }}</ion-label>
              </ion-col>
              <ion-col size="auto">
                <ion-button v-if="!showAll && selectedSubLeague && selectedSubLeague != selectedLeague.subLeagues[selectedLeague.subLeagues.length-1]" fill="clear" @click="goForward()">
                  <ion-icon style="zoom: 1.4; margin-left: 1px;" color="primary" :md="chevronForwardOutline" :ios="chevronForwardOutline"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid style="padding: 0px;">
            <ion-row class="ion-rowheader">
              <ion-col size="auto">
                <VueScrollingTable :style="result.style" class="responstable" id="test" deadAreaColor="#f2f2f2">
                  <template #thead>
                    <tr>
                      <th v-for="(item, i) in result.spieltage" :key="i" :value="item" :style="getStyleTH(item)">{{ (item.name + (item.isverified ? '' : '*')) }} <br><span :style="'font-size: small;'" v-if="item && item.date">{{ (weekDays[dayjs(item.date).day()] + ', ' + dayjs(item.date).format('DD.MM.YY')) }}</span></th>
                    </tr>
                  </template>
                  <template #tbody>
                    <tr v-for="(team, i) in result.teams" :key="i" :value="team" >
                      <td v-for="(item, j) in team.data" :key="j" :value="item" :style="getStyle(item)" @click="showTeam(team, item, result.name)">
                        <ion-icon v-if="j == 0 && team.goes_up_down == 1" style="zoom: 1.2;" color="success" :md="arrowUpSharp" :ios="arrowUpSharp"></ion-icon>
                          <ion-icon v-if="j == 0 && team.goes_up_down == 2" style="zoom: 1.2;" color="danger" :md="arrowDownSharp" :ios="arrowDownSharp"></ion-icon>
                          <span :style="j >= (team.data.length - 3) || j == 0 || j == 1 ? 'font-weight: bold;' : ''">{{ item.value + (j == 1 && team.is_stechen == 1 && team.is_random == 0 ? ' (**)' : '') + (j == 1 && team.is_random == 1 ? ' (***)' : '') }}</span>
                      </td>
                    </tr>
                  </template>
                </VueScrollingTable>
              </ion-col>
            </ion-row>
          </ion-grid>
      </section>

      <ion-grid>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto"><a href='javascript:print()'>Drucken</a><br><br></ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">(*)</ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Es handelt sich um ein vorläufiges Ergebnis</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">(**)</ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Das Ergebnis ist gestochen</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">(***)</ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Das Ergebnis wurde zufällig gestochen</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">(+)</ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Strafschläge</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">(X)</ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Die Mannschaft ist am Spieltag nicht angetreten</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">(DQ)</ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Die Mannschaft ist am Spieltag disqualifiziert</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto"><ion-icon style="zoom: 1.0;" color="success" :md="arrowUpSharp" :ios="arrowUpSharp"></ion-icon></ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Aufsteiger</ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto"><ion-icon style="zoom: 1.0;" color="danger" :md="arrowDownSharp" :ios="arrowDownSharp"></ion-icon></ion-col>
          <ion-col style="color: #00a684; margin-left: 5px;font-size: 14px;" class="ion-no-padding" size="auto">Absteiger</ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script>
import { toastController, modalController, loadingController, IonTitle, IonButton, IonToolbar, IonHeader, 
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonCheckbox,
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'
import findLeaguesQueryFile from '../graphql/findLeagues.query.gql'
import findLeagueResultQueryFile from '../graphql/findLeagueResult.query.gql'
import { useRoute, useRouter } from 'vue-router';
import { chevronBackOutline, chevronForwardOutline, arrowDownSharp, arrowUpSharp, chevronDownOutline, chevronUpOutline, expandOutline, contractOutline } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import JugendDialog from './JugendDialog'
import TeamDialog from './TeamDialog'
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

export default defineComponent({
  name: 'Home',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, VueScrollingTable,
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonCheckbox
  },
  data() {
    return {
      logo: require('@/assets/images/logo/nrwlogo.png'),
      weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }
  },
  setup() {
    const selectedLeague = ref()
    const selectedSubLeague = ref(null)
    const route = useRoute()
    const router = useRouter()
    let year = route.query.year || new Date().getFullYear().toString()
    let loadingSpinner = null
    const showAll = ref(false)
    const useVisibilty = ref(false)

    const {onResult, result: leaguesFoundResult } = useQuery(findLeaguesQueryFile, () => ({ year: year }), { fetchPolicy: 'no-cache' } )
    const leaguesFound = useResult(leaguesFoundResult, null, data => data.findLeagues.leagues)
    onResult(queryResult => {
      if (queryResult.data && queryResult.data.findLeagues && queryResult.data.findLeagues.leagues && queryResult.data.findLeagues.leagues.length > 0) {
        if (route.params.lid) {
          selectedLeague.value = queryResult.data.findLeagues.leagues.find(f => f.leagueId == route.params.lid)
        }
        else {
          selectedLeague.value = queryResult.data.findLeagues.leagues[0]
          router.push('/' + selectedLeague.value.leagueId + (route.query.year ? '/?year=' + route.query.year  : ''))
        }
  
        if (route.params.slid) {
          selectedSubLeague.value = queryResult.data.findLeagues.leagues.find(f => f.leagueId == route.params.lid).subLeagues.find(f => f.leagueId)
        }
        else {
          selectedSubLeague.value = queryResult.data.findLeagues.leagues[0].subLeagues[0]
          router.push('/' + selectedLeague.value.leagueId + '/' + selectedSubLeague.value.leagueId  + (route.query.year ? '/?year=' + route.query.year  : ''))
        }
      }
    })

    const {refetch: findLeagueResultRefetch, result: findLeagueResultResult, loading: findLeagueResultLoading } = useQuery(findLeagueResultQueryFile, () => ({ leagueId: selectedSubLeague.value == null || showAll.value ? '0' : selectedSubLeague.value.leagueId, subLeagues: selectedLeague.value == null || selectedLeague.value.subLeagues == null ? [] : selectedLeague.value.subLeagues, useVisibilty: useVisibilty.value }), () => ({ fetchPolicy: 'no-cache', enabled: selectedSubLeague.value != null }) )
    const resultFound = useResult(findLeagueResultResult, null, data => data.findLeagueResult.result)
  
    watch(findLeagueResultLoading, (newLoading, prevLoading) => {
      if (!newLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (newLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              res.present().then(() => { 
                if (!findLeagueResultLoading.value) 
                  res.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      leaguesFound, selectedLeague, selectedSubLeague, findLeagueResultRefetch, chevronBackOutline, chevronForwardOutline, resultFound, dayjs, arrowDownSharp, arrowUpSharp, showAll, isPlatform, year, useVisibilty, chevronDownOutline, chevronUpOutline, expandOutline, contractOutline
    }
  },
  methods: {
    async toggleAll() {
      this.useVisibilty = !this.useVisibilty
    },
    async showTeam(team, item, subLeagueName) {
      const modal = await modalController
        .create({
          cssClass: 'show-modal',
          component: TeamDialog,
          componentProps: {
            propsData: {
              team: team,
              item: item,
              selectedLeague: this.selectedLeague,
              subLeagueName: subLeagueName,
            }
          },
        })
      return modal.present();
    },
    async showJugendEndTabelle() {
      const modal = await modalController
        .create({
          cssClass: 'show-modal',
          component: JugendDialog,
          componentProps: {
            propsData: {
              selectedLeague: this.selectedLeague,
            }
          },
        })
      return modal.present();
    },
    getStyleTH(item) {
      return item.style + (item.isVisible == false ? ';display: none' : ';font-size: small;')
    },
    getStyle(data) {
      if (data.homeTeam != null && data.homeTeam)
        return ("min-width=88px; background: #00a684; color: #FFFFFF; min-height: 50px; padding-top: 15px; border-right: 1px solid grey; border-bottom: 1px solid grey; font-size: 12px;" + (data.isVisible == false ? 'display: none;': ''))
      return (data.style + "min-height: 50px; padding-top: 15px; border-right: 1px solid grey; border-bottom: 1px solid grey; font-size: 12px;" + (data.isVisible == false ? 'display: none;': ''))
    },
    changeLeague(league) {
      if (this.$route.params.slid && league.subLeagues.find(f => f.leagueId == this.$route.params.slid))
        this.selectedSubLeague = league.subLeagues.find(f => f.leagueId == this.$route.params.slid)
      else
        this.selectedSubLeague = league.subLeagues[0]
      this.$router.push('/' + league.leagueId + '/' + this.selectedSubLeague.leagueId  + (this.$route.query.year ? '/?year=' + this.$route.query.year  : ''))
    },
    changeSubLeague(league) {
      this.$router.push('/' +  this.selectedLeague.leagueId + '/' + league.leagueId  + (this.$route.query.year ? '/?year=' + this.$route.query.year  : ''))
    },
    goBack() {
      const index = this.selectedLeague.subLeagues.findIndex(f => f.leagueId == this.selectedSubLeague.leagueId)
      const prevSubLeague = this.selectedLeague.subLeagues[index-1]
      this.$router.push('/' + this.selectedLeague.leagueId + '/' + prevSubLeague.leagueId  + (this.$route.query.year ? '/?year=' + this.$route.query.year  : ''))
      this.selectedSubLeague = prevSubLeague
    },
    goForward() {
      const index = this.selectedLeague.subLeagues.findIndex(f => f.leagueId == this.selectedSubLeague.leagueId)
      const nextSubLeague = this.selectedLeague.subLeagues[index+1]
      this.$router.push('/' + this.selectedLeague.leagueId + '/' + nextSubLeague.leagueId  + (this.$route.query.year ? '/?year=' + this.$route.query.year  : ''))
      this.selectedSubLeague = nextSubLeague
    },
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>

/* -------------------- Page Styles (not required) */
div { margin: 0px; }

/* -------------------- Select Box Styles: bavotasan.com Method (with special adaptations by ericrasch.com) */
/* -------------------- Source: http://bavotasan.com/2011/style-select-box-using-only-css/ */

P   { 
	text-align: center; 
	margin:0px;
}

.styled-select {
   height: 29px;
   overflow: hidden;
   width: 180px;
  -webkit-print-color-adjust: exact;
}

.styled-select select {
   background: transparent;
   border: none;
   font-size: 14px;
   height: 29px;
   padding: 5px; /* If you add too much padding here, the options won't show in IE */
   width: 368px;
  -webkit-print-color-adjust: exact;
}

.styled-select.slate {
   height: 34px;
   width: 340px;
  -webkit-print-color-adjust: exact;
}

.styled-select.slate select {
   border: 1px solid #ccc;
   font-size: 16px;
   height: 34px;
   width: 368px;
  -webkit-print-color-adjust: exact;
}

/* -------------------- Rounded Corners */
.rounded {
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
  -webkit-print-color-adjust: exact;
}

.semi-square {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
  -webkit-print-color-adjust: exact;
}

/* -------------------- Colors: Background */
.slate   { background-color: #ddd; }
.green   { background-color: #779126; }
.blue    { background-color: #3b8ec2; }
.yellow  { background-color: #eec111; }
.black   { background-color: #000; }

/* -------------------- Colors: Text */
.slate select   { color: #000; }
.green select   { color: #fff; }
.blue select    { color: #fff; }
.yellow select  { color: #000; }
.black select   { color: #fff; }


/* -------------------- Select Box Styles: danielneumann.com Method */
/* -------------------- Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ */
#mainselection select {
   border: 0;
   color: #EEE;
   background: transparent;
   font-size: 20px;
   font-weight: bold;
   padding: 2px 10px;
   width: 478px;
   *width: 350px;
   *background: #58B14C;
   -webkit-appearance: none;
  -webkit-print-color-adjust: exact;
}

#mainselection {
   overflow:hidden;
   width:450px;
   -moz-border-radius: 9px 9px 9px 9px;
   -webkit-border-radius: 9px 9px 9px 9px;
   border-radius: 9px 9px 9px 9px;
   box-shadow: 1px 1px 11px #330033;
   background: #58B14C no-repeat scroll 319px center;
  -webkit-print-color-adjust: exact;
}


/* -------------------- Select Box Styles: stackoverflow.com Method */
/* -------------------- Source: http://stackoverflow.com/a/5809186 */
select#soflow, select#soflow-color {
   -webkit-appearance: button;
   -webkit-border-radius: 2px;
   -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
   -webkit-padding-end: 20px;
   -webkit-padding-start: 2px;
   -webkit-user-select: none;
  -webkit-print-color-adjust: exact;
   background-position: 97% center;
   background-repeat: no-repeat;
   border: 1px solid #AAA;
   color: #555;
   font-size: inherit;
   margin: 0px;
   overflow: hidden;
   padding: 5px 10px;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 700px;
}

select#soflow-color {
   color: #fff;
   background-color: #00a684;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
   padding-left: 15px;
  -webkit-print-color-adjust: exact;
}

.responstable {
  margin: 1em 0;
  width: auto;
  overflow: hidden;
  background: #FFF;
  color: #024457;
  border-radius: 10px;
  border: 1px solid #ff5560;
  -webkit-print-color-adjust: exact;
}
.responstable tr {
  border: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
}
.responstable tr:nth-child(odd) {
  background-color: #EAF3F3 !important;
  -webkit-print-color-adjust: exact;
}
.responstable th {
  display: none;
  border: 1px solid #FFF;
  background-color: #ff5560;
  color: #FFF;
  padding: 1em;
  -webkit-print-color-adjust: exact;
}
.responstable th:first-child {
  display: table-cell;
  text-align: center;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2) {
  display: table-cell;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2) span {
  display: none;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2):after {
  content: attr(data-th);
  -webkit-print-color-adjust: exact;
}
@media (min-width: 0px) {
  .responstable th:nth-child(2) span {
    display: block;
  -webkit-print-color-adjust: exact;
  }
  .responstable th:nth-child(2):after {
    display: none;
  -webkit-print-color-adjust: exact;
  }
}
.responstable td {
  display: none;
  word-wrap: break-word;
  max-width: 7em;
  -webkit-print-color-adjust: exact;
}
.responstable td:first-child {
  display: table-cell;
  text-align: center;
  border-right: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
}
@media (min-width: 0px) {
  .responstable td {
    border: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
  }
}
.responstable th, .responstable td {
  text-align: left;
  margin: .5em 1em;
  -webkit-print-color-adjust: exact;
}
@media (min-width: 0px) {
  .responstable th, .responstable td {
    display: table-cell;
    padding: 1em;
  -webkit-print-color-adjust: exact;
  }
}

body {
  padding: 0 2em;
  font-family: Arial, sans-serif;
  color: #00a684;
  background: #f2f2f2;
  -webkit-print-color-adjust: exact;
}

h1 {
  font-family: Verdana;
  font-weight: normal;
  color: #024457;
  -webkit-print-color-adjust: exact;
}
h1 span {
  color: #ff5560;
  -webkit-print-color-adjust: exact;
}

ion-col[class*="result"]:first-of-type {border-left: 1px solid grey; }

ion-button {
 text-transform: none;
}

ion-item-divider{
  margin-top: 0px;
  min-height: 1px!important;
}

.clickable {
    cursor: pointer;
}
.ion-rowheader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

h1 {
  font-family: Verdana;
  font-weight: normal;
  color: #024457;
  -webkit-print-color-adjust: exact;
}
h1 span {
  color: #ff5560;
  -webkit-print-color-adjust: exact;
}

h2 {
  font-family: Verdana;
  font-weight: normal;
  color: #00a684;
  -webkit-print-color-adjust: exact;
}

ion-select {
  /* Applies to the value and placeholder color */
  color: #00a684;

  /* Set a different placeholder color */
  --placeholder-color: #971e49;

  /* Set full opacity on the placeholder */
  --placeholder-opacity: 1;
}

section {
  padding: 0 2em;
  font-family: Arial, sans-serif;
  color: #00a684;
  background: #f2f2f2;
  -webkit-print-color-adjust: exact;
}

section:not(.full-width),
.full-width > header {
  padding: 10px 10px;
}
</style>

<style lang="scss">

img{
  width:100%;
  height:100%;
}

.myCard{
  position:relative;

}

.myOverlay{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    opacity: 1;
    background: transparent;
    color: black;

}

</style>