<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>Mannschaftsergebnis</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title style="color: #00a684">{{ teamName }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-card style="background-color: #EAF3F3" v-for="(result, i) in teamResult" :key="i" :value="result">
          <ion-card-header>
            <ion-card-title>{{ result.name }} {{ weekDays[dayjs(result.date).day()] + ', ' + dayjs(result.date).format('DD.MM.YYYY') }} {{ result.clubname }}</ion-card-title>
            <ion-card-title v-if="result.tee != ''">{{ result.tee }}</ion-card-title>
          </ion-card-header>
          <VueScrollingTable class="responstable" v-if="result.players && result.players.length > 0" :style="!selectedLeague.name.includes('Jugendliga Bezirksliga') ? 'max-width: 590px;' : 'max-width: 502px;'">
          <template #thead>
            <tr>
              <th style="min-width: 180px; width: 180px;">Spieler</th>
              <th style="min-width: 84px; width: 84px;">HCPI™</th>
              <th style="min-width: 150px; width: 150px;">Course Handicap</th>
              <th style="min-width: 88px; width: 88px;">Brutto</th>
              <th style="min-width: 88px; width: 88px;" v-if="!selectedLeague.name.includes('Jugendliga Bezirksliga')">über CR</th>
            </tr>
          </template>
          <template #tbody>
            <tr v-for="(player, i) in result.players" :key="i" :value="player" @click="openScorecard(player)">
              <td style="min-width: 180px; width: 180px;"><span>{{ player.title + " " + player.familyname + " " + player.name }}</span></td>
              <td style="min-width: 84px; width: 84px;"><span>{{ player.hcp }}</span></td>
              <td style="min-width: 150px; width: 150px;"><span>{{ player.playinhcp }}</span></td>
              <td style="min-width: 88px; width: 88px;"><span>{{ player.erg_brutto }}</span></td>
              <td style="min-width: 88px; width: 88px;" v-if="!selectedLeague.name.includes('Jugendliga Bezirksliga')"><span>{{ player.plusCR }}</span></td>
            </tr>
          </template>
          </VueScrollingTable>
          </ion-card>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonListHeader,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, arrowUpSharp, arrowDownSharp } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import * as dayjs from 'dayjs'; 
import findTeamResultQueryFile from '../graphql/findTeamResult.query.gql'
import ScorecardDialog from './ScorecardDialog'
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"

export default defineComponent({
  name: 'ShowResultListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonListHeader, IonAvatar, VueScrollingTable
              },
  props: ['propsData'],
  setup(props) {
    let team = props.propsData.team
    let item = props.propsData.item
    let selectedLeague = props.propsData.selectedLeague
    let subLeagueName = props.propsData.subLeagueName

    let ltId = item.ltId
    if (item.ltId == null) {
      const id = team.data.find(f => f.ltId != null)
      if (id)
        ltId = id.ltId
    }

    const teamName = team.data[1].value + " - " + selectedLeague.name + " - " + subLeagueName

    const {result: findTeamResult } = useQuery(findTeamResultQueryFile, () => ({ teamId: team.teamId }), { fetchPolicy: 'no-cache' } )
    const teamResult = useResult(findTeamResult, null, data => data.findTeamResult.spieltage)

    return { 
      closeOutline, arrowUpSharp, arrowDownSharp, team, item, teamName, ltId, teamResult, dayjs, selectedLeague
    }
  },
  data() {
    return {
      weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }
  },
  methods: {
    async openScorecard(player) {
      const modal = await modalController
        .create({
          cssClass: 'showscorecard-modal',
          component: ScorecardDialog,
          componentProps: {
            propsData: {
              player: player,
              selectedLeague: this.selectedLeague
            }
          },
        })
      return modal.present();
    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>

/* -------------------- Page Styles (not required) */
div { margin: 0px; }

/* -------------------- Select Box Styles: bavotasan.com Method (with special adaptations by ericrasch.com) */
/* -------------------- Source: http://bavotasan.com/2011/style-select-box-using-only-css/ */

P   { 
	text-align: center; 
	margin:0px;
}

.styled-select {
   height: 29px;
   overflow: hidden;
   width: 180px;
  -webkit-print-color-adjust: exact;
}

.styled-select select {
   background: transparent;
   border: none;
   font-size: 14px;
   height: 29px;
   padding: 5px; /* If you add too much padding here, the options won't show in IE */
   width: 368px;
  -webkit-print-color-adjust: exact;
}

.styled-select.slate {
   height: 34px;
   width: 340px;
  -webkit-print-color-adjust: exact;
}

.styled-select.slate select {
   border: 1px solid #ccc;
   font-size: 16px;
   height: 34px;
   width: 368px;
  -webkit-print-color-adjust: exact;
}

/* -------------------- Rounded Corners */
.rounded {
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
  -webkit-print-color-adjust: exact;
}

.semi-square {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   border-radius: 5px;
  -webkit-print-color-adjust: exact;
}

/* -------------------- Colors: Background */
.slate   { background-color: #ddd; }
.green   { background-color: #779126; }
.blue    { background-color: #3b8ec2; }
.yellow  { background-color: #eec111; }
.black   { background-color: #000; }

/* -------------------- Colors: Text */
.slate select   { color: #000; }
.green select   { color: #fff; }
.blue select    { color: #fff; }
.yellow select  { color: #000; }
.black select   { color: #fff; }


/* -------------------- Select Box Styles: danielneumann.com Method */
/* -------------------- Source: http://danielneumann.com/blog/how-to-style-dropdown-with-css-only/ */
#mainselection select {
   border: 0;
   color: #EEE;
   background: transparent;
   font-size: 20px;
   font-weight: bold;
   padding: 2px 10px;
   width: 478px;
   *width: 350px;
   *background: #58B14C;
   -webkit-appearance: none;
  -webkit-print-color-adjust: exact;
}

#mainselection {
   overflow:hidden;
   width:450px;
   -moz-border-radius: 9px 9px 9px 9px;
   -webkit-border-radius: 9px 9px 9px 9px;
   border-radius: 9px 9px 9px 9px;
   box-shadow: 1px 1px 11px #330033;
   background: #58B14C no-repeat scroll 319px center;
  -webkit-print-color-adjust: exact;
}


/* -------------------- Select Box Styles: stackoverflow.com Method */
/* -------------------- Source: http://stackoverflow.com/a/5809186 */
select#soflow, select#soflow-color {
   -webkit-appearance: button;
   -webkit-border-radius: 2px;
   -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
   -webkit-padding-end: 20px;
   -webkit-padding-start: 2px;
   -webkit-user-select: none;
  -webkit-print-color-adjust: exact;
   background-position: 97% center;
   background-repeat: no-repeat;
   border: 1px solid #AAA;
   color: #555;
   font-size: inherit;
   margin: 0px;
   overflow: hidden;
   padding: 5px 10px;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 700px;
}

select#soflow-color {
   color: #fff;
   background-color: #00a684;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 20px;
   padding-left: 15px;
  -webkit-print-color-adjust: exact;
}

.responstable {
  margin: 1em 0;
  width: 100%;
  overflow: hidden;
  background: #FFF;
  color: #024457;
  border-radius: 10px;
  border: 1px solid #ff5560;
  -webkit-print-color-adjust: exact;
}
.responstable tr {
  border: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
}
.responstable tr:nth-child(odd) {
  background-color: #EAF3F3;
  -webkit-print-color-adjust: exact;
}
.responstable th {
  display: none;
  border: 1px solid #FFF;
  background-color: #ff5560;
  color: #FFF;
  padding: 1em;
  -webkit-print-color-adjust: exact;
}
.responstable th:first-child {
  display: table-cell;
  text-align: center;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2) {
  display: table-cell;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2) span {
  display: none;
  -webkit-print-color-adjust: exact;
}
.responstable th:nth-child(2):after {
  content: attr(data-th);
  -webkit-print-color-adjust: exact;
}
@media (min-width: 180px) {
  .responstable th:nth-child(2) span {
    display: block;
  -webkit-print-color-adjust: exact;
  }
  .responstable th:nth-child(2):after {
    display: none;
  -webkit-print-color-adjust: exact;
  }
}
.responstable td {
  display: block;
  word-wrap: break-word;
  max-width: 7em;
  -webkit-print-color-adjust: exact;
}
.responstable td:first-child {
  display: table-cell;
  text-align: center;
  border-right: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
}
@media (min-width: 180px) {
  .responstable td {
    border: 1px solid #D9E4E6;
  -webkit-print-color-adjust: exact;
  }
}
.responstable th, .responstable td {
  text-align: left;
  margin: .5em 1em;
  -webkit-print-color-adjust: exact;
}
@media (min-width: 180px) {
  .responstable th, .responstable td {
    display: table-cell;
    padding: 1em;
  -webkit-print-color-adjust: exact;
  }
}

body {
  padding: 0 2em;
  font-family: Arial, sans-serif;
  color: #00a684;
  background: #f2f2f2;
  -webkit-print-color-adjust: exact;
}

h1 {
  font-family: Verdana;
  font-weight: normal;
  color: #024457;
  -webkit-print-color-adjust: exact;
}
h1 span {
  color: #ff5560;
  -webkit-print-color-adjust: exact;
}


ion-col[class*="result"]:first-of-type {border-left: 1px solid grey; }

.clickable {
    cursor: pointer;
}

.responstable ion-row:nth-child(odd) {
  background-color: #EAF3F3;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  padding: 0 2em;
  font-family: Arial, sans-serif;
  color: #00a684;
  background: #f2f2f2;
  -webkit-print-color-adjust: exact;
}

section:not(.full-width),
.full-width > header {
  padding: 10px 10px;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-list {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-list:first-of-type {
  border-top: 0.55px solid #c8c7cc;
}
section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>