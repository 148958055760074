<template>
  <ion-app>
  <ion-split-pane content-id="main">
    <!-- the main content -->
     <div class="ion-page" id="main">
      <ion-router-outlet></ion-router-outlet>
    </div>
  </ion-split-pane>
  </ion-app>
</template>

<script>
import { toastController, modalController, IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, 
IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonApp, IonContent, IonIcon, IonItem, 
IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, IonSelect, 
IonSelectOption, IonFabList, IonFabButton, IonFab, IonBadge, alertController, IonProgressBar, IonCardSubtitle, IonCardTitle, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import {  } from 'ionicons/icons';
import { useQuery, useResult, useMutation} from '@vue/apollo-composable'

import {
  Plugins,
} from '@capacitor/core';

const { PushNotifications } = Plugins;

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonImg,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonBadge, IonProgressBar, IonCardSubtitle, IonCardTitle,
  },
  setup() {
    return { 
    }
  },
  methods: {
  },
  data() {
    return {
    }
  }
});
</script>

<style lang="scss">

.show-modal .modal-wrapper {
    width: 95%;
    height: 95%;
 }

.showscorecard-modal .modal-wrapper {
    width: 85%;
    height: 500px;
 }

.register-modal .modal-wrapper {
    height: 75%;
 }

.booking-modal .modal-wrapper {
    height: 40%;
 }

</style>